<template>
  <div style="background-color: #FFFFFF;">
    <el-tabs>
      <el-tab-pane label="关注列表">
        <el-row>
          <el-col :span="24">
            <div class="ac-list">
              <el-table :data="tableData" border :header-cell-style="headerColor">
                <el-table-column type="index" label="序号" width="80" align="center">
                </el-table-column>
                <el-table-column prop="companyName"  label="企业名称" align="center">
                  <template #default="scope">
                  <span class="btn-hover-company" @click="handleToCompany(scope.row)">{{scope.row.companyName}}<span v-if="scope.row.unreadMessagesStatus == 1" class="newClass">new</span></span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                  <template #default="scope">
                  <span class="btn-hover-foucs" @click="handleCancelFocus (scope.$index, scope.row)">取消关注</span>
                  </template>
                </el-table-column>
                <template #empty style="width: 100%;">
                  <el-empty :image-size="200"></el-empty>
                </template>
              </el-table>
            </div>
            <div class="ac-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="page.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="currentChange"
                :total="page.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { getFoucsByUserId, updateStatusByUserIdAndCompanyId } from '@/api/user.js'
import { DelByUserIdAndCompanyId } from '@/api/general-query/company.js'

export default {
  name: 'attention-company',
  data () {
    return {
      tableData: null,
      page: {
        pagerCount: 10,
        currentPage: 1,
        total: 0
      }
    }
  },
  mounted () {
    this.loadData(1)
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'mesCont',
      'foucsCont'
    ])
  },
  methods: {
    handleToCompany (e) {
      const vm = this
      if (e.unreadMessagesStatus === 1) {
        const data = {
          userId: JSON.parse(this.$store.getters.userInfo).userId,
          companyId: e.companyId
        }
        updateStatusByUserIdAndCompanyId(data).then(result => {
          this.$store.dispatch('setFoucsCont', this.foucsCont - 1)
        })
      }
      vm.$store.dispatch('setBusId', e.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    },
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    // 翻页时触发
    currentChange (currentPage) {
      this.currentPage = currentPage
      this.loadData(currentPage)
    },
    loadData (currentPage) {
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: currentPage,
        pageSize: this.page.pagerCount
      }
      getFoucsByUserId(params).then(result => {
        if (result.code === 0) {
          this.page.total = result.data.total
          this.tableData = result.data.list
        } else {
          ElMessage.error({ message: result.msg, offset: 60 })
        }
      }).catch(error => {
        ElMessage.error({ message: error, offset: 60 })
      })
    },
    handleCancelFocus (index, row) {
      const params = {
        userId: row.userId,
        companyId: row.companyId
      }
      DelByUserIdAndCompanyId(params).then(result => {
        if (result.code === 0) {
          ElMessage.success({ message: '取消成功！', offset: 60 })
          this.loadData(this.currentPage)
        } else {
          ElMessage.error({ message: result.msg, offset: 60 })
        }
      }).catch(error => {
        ElMessage.error({ message: error, offset: 60 })
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 100px;
}

.pc-title {
  font-size: 16px;
  color: #333333;
}

.ac-toolbar {
  margin: 20px;
  display: flex;
  justify-content: flex-start;
}

.ac-list {
  margin: 20px;
}

.ac-page {
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
}
.btn-hover-foucs {
  color: #5D6FE9;
  cursor: pointer;
}
.btn-hover-foucs:hover {
  font-weight: bold;
}
.btn-hover-company {
  cursor: pointer;
}
.btn-hover-company:hover{
  font-weight: bold;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
.newClass {
  background: red;
  color: #fff;
  margin-left: 5px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 10px 0px 10px 0px;
}
</style>
