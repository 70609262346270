import api from '@/api/index'
// axios
import request from '@/utils/request'

/**
 * 通过用户id和公司id组查询用户关注企业
 * @param data
 */
export function getFoucsByUserIdAndCompanyIds (data) {
  return request({
    url: api.GetFoucsByUserIdAndCompanyIds,
    method: 'get',
    params: data,
    hideloading: true
  })
}

/**
 * 通过用户id和公司id关注企业
 * @param data
 */
export function InsertUserFoucs (data) {
  return request({
    url: api.InsertUserFoucs,
    method: 'post',
    data,
    hideloading: true
  })
}

/**
 * 通过用户id和公司id取消关注企业
 * @param data
 */
export function DelByUserIdAndCompanyId (data) {
  return request({
    url: api.DelByUserIdAndCompanyId,
    method: 'post',
    data,
    hideloading: true
  })
}
